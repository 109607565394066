import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import type { VirtuosoHandle } from 'react-virtuoso';
import { gql } from '@soundxyz/gql-string';
import { ROUTES } from '../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  FeatureTypename,
  getFragment,
  MessageChannelType,
  ReplyToMessageFragmentDoc,
  TierTypename,
  VaultType,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useSendMessage } from '../../hooks/useSendMessage';
import { useSetLastReadMessageChannel } from '../../hooks/useSetLastReadMessageChannel';
import type { TierFeatures } from '../../hooks/useTierFeatures';
import {
  setReplyToMessage,
  useReplyToMessage,
  useSetActiveArtistChatChannelId,
  useSetActiveArtistChatHandle,
  useVaultMessageChannel,
} from '../../hooks/useVaultMessageChannel';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { HorizontalMessageMediaCarousel } from '../chat/HorizontalMessageMediaCarousel';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { NewMessageLayout } from '../layouts/MessageChannelLayout';
import { GifKeyboard } from '../message/GifKeyboard';
import { MessageTextInput, SkeletonMessageTextInput } from '../message/MessageTextInput';
import { ReplyToMessage } from '../message/ReplyToMessage';
import {
  EmptyMessageChannel,
  MessageChannelView,
  SkeletonMessageChannel,
} from './MessageChannelView';

gql(/* GraphQL */ `
  fragment messageChannelDetails on MessageChannelDetails {
    channelRecipientHasActivePermissions
    coverImage {
      id
      imageSmallUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      dominantColor
    }
    receiptCount
    subscriptionTierLevel
    showVerifiedBadge
    username
    subtitleText
    titleText
  }

  query GetMessageChannel(
    $input: QueryMessageChannelInput!
    $first: Int!
    $after: String
    $asArtistId: UUID
  ) {
    messageChannel(input: $input) {
      __typename

      ... on QueryMessageChannelSuccess {
        data {
          id
          details {
            ...messageChannelDetails
          }
          artist {
            id
            name
            linkValue
            profileImage {
              id
              artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
            }
            mainVaultId
          }
          vault {
            id
            type
            artist: artistProfile {
              id
              name
              linkValue
              profileImage {
                id
                artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
              }
              mainVaultId
            }
            activeSubscription {
              id
              ...ActiveSubscriptionFeatures
            }
            price
            contentCount
            tiers {
              __typename
              enabledFeatures {
                feature {
                  __typename
                }
              }
            }
          }
          channelType
          ...messageChannelView
        }
      }
    }
  }
`);

export const VaultMessageChannelView = ({
  artistId,
  artistName,
  contentCount,
  isOwner,
  artistLinkValue,
  artistProfileImage,
  activeSubscriptionFeatures,
  showVaultNav,
  messageChannelId,
}: {
  artistId: string | undefined;
  artistName: string | undefined;
  contentCount: number | undefined;
  isOwner: boolean;
  artistLinkValue: string | undefined;
  artistProfileImage: { id: string; artistSmallProfileImageUrl: string | null } | null | undefined;
  activeSubscriptionFeatures: TierFeatures | null;
  showVaultNav: boolean;
  messageChannelId: string | undefined;
}) => {
  const { loginStatus } = useAuthContext();
  const replyToMessageFrag = useReplyToMessage();
  const { artistHandle } = useArtistHandle();
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const hasDMWriteAccess = activeSubscriptionFeatures?.enabledFeatures.DMWrite === true || isOwner;
  const hasChatWriteAccess =
    activeSubscriptionFeatures?.enabledFeatures.ChatWrite === true || isOwner;

  useSetActiveArtistChatHandle();

  const replyToMessage = getFragment(ReplyToMessageFragmentDoc, replyToMessageFrag);
  const [gifKeyboardOpen, setGifKeyboardOpen] = useState(false);

  const {
    query: { firstPage, orderedList: messages, hasNextPage, loadMoreNextPage },
    onMessageChannelUpdate,
    pinnedMessages,
    messageChannel: messageChannelLayoutInfo,
    isInitialLoading,
    clearCustomPages,
  } = useVaultMessageChannel();

  useEffect(() => {
    return () => {
      clearCustomPages();
    };
  }, [clearCustomPages]);

  const navigate = useNavigate();

  useSetActiveArtistChatChannelId({
    channelId: messageChannelId,
  });

  const messageChannel =
    firstPage?.data.messageChannel?.__typename === 'QueryMessageChannelSuccess'
      ? firstPage?.data.messageChannel?.data
      : undefined;

  const asArtist =
    isOwner && artistId && artistLinkValue && artistName
      ? {
          id: artistId,
          linkValue: artistLinkValue,
          name: artistName,
          profileImage:
            artistProfileImage?.id && artistProfileImage?.artistSmallProfileImageUrl
              ? {
                  id: artistProfileImage.id,
                  artistSmallProfileImageUrl: artistProfileImage.artistSmallProfileImageUrl,
                }
              : null,
        }
      : null;

  const { sendMessage } = useSendMessage({
    asArtist,
    messageChannelId: messageChannel?.id,
    activeSubscriptionTier: activeSubscriptionFeatures?.tier || null,
  });

  useSetLastReadMessageChannel({
    messageChannelId: messageChannel?.id,
    asArtistId: asArtist?.id,
  });

  const isGroupChat = messageChannel?.channelType === MessageChannelType.Vault;
  const artist = messageChannel?.artist || messageChannel?.vault?.artist;
  const noArtist = artist == null || messageChannelLayoutInfo == null;

  const chatAvailableForFreeUsers = messageChannel?.vault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(
      ({ feature }) =>
        feature.__typename === (isGroupChat ? FeatureTypename.ChatRead : FeatureTypename.DmRead),
    );

  useEffect(() => {
    if (isInitialLoading || loginStatus === 'LOADING') return;
    if (noArtist) {
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  }, [noArtist, artistHandle, navigate, isInitialLoading, loginStatus]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (
    isInitialLoading ||
    loginStatus === LoginStatus.LOADING ||
    noArtist ||
    artist == null ||
    messageChannel == null ||
    messageChannelLayoutInfo == null
  ) {
    return (
      <LoadingChat
        vaultArtistName={artistName}
        vaultArtistImage={artistProfileImage?.artistSmallProfileImageUrl}
        activeSubscriptionFeatures={activeSubscriptionFeatures}
        messageChannelId={messageChannel?.id}
        isGroupChat={isGroupChat}
        showVaultNav={showVaultNav}
        chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
        vaultId={messageChannel?.vault?.id}
        artistHandle={artistHandle}
      />
    );
  }

  return (
    <NewMessageLayout
      artistName={artistName}
      contentCount={contentCount}
      artistProfileImage={artistProfileImage?.artistSmallProfileImageUrl}
      activeSubscriptionFeatures={activeSubscriptionFeatures}
      vaultId={messageChannel.vault?.id}
      messageChannelDetails={messageChannel.details}
      messageChannelId={messageChannelLayoutInfo.id}
      chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
      isGroupChat={isGroupChat}
      artistHandle={artistHandle}
      showVaultNav={showVaultNav}
      secondaryFooter={
        <>
          {replyToMessage && <ReplyToMessage message={replyToMessage} />}
          <HorizontalMessageMediaCarousel artistName={artist.name} />
          {gifKeyboardOpen ? (
            <GifKeyboard
              onSelect={gif => {
                const gifAttachment = {
                  id: gif.id,
                  url: gif.images.fixed_width.url,
                  title: gif.title,
                  aspectRatio:
                    parseFloat(gif.images.fixed_width.width) /
                    parseFloat(gif.images.fixed_width.height),
                };
                sendMessage({
                  content: '',
                  gifAttachment: gifAttachment,
                  replyToMessage: replyToMessage,
                  vaultContent: null,
                  activeSubscriptionTier: activeSubscriptionFeatures?.tier || null,
                });
                virtuosoRef.current?.scrollToIndex({
                  index: 0,
                  behavior: 'smooth',
                });
                setReplyToMessage(null);
                setGifKeyboardOpen(false);
              }}
              closeKeyboard={() => setGifKeyboardOpen(false)}
            />
          ) : (
            <>
              {(isGroupChat && hasChatWriteAccess) || (!isGroupChat && hasDMWriteAccess) ? (
                <MessageTextInput
                  messageChannel={messageChannel}
                  activeSubscriptionTier={activeSubscriptionFeatures?.tier || null}
                  isOwner={isOwner}
                  asArtist={asArtist}
                  gifButtonClick={() => setGifKeyboardOpen(true)}
                  vaultId={artist.mainVaultId}
                  artist={artist}
                  activeSubscriptionFeatures={activeSubscriptionFeatures}
                  virtuosoRef={virtuosoRef}
                />
              ) : (
                <View className="flex flex-col items-center justify-center gap-4 border-0 border-t-[1px] border-solid border-vault_text/5 p-4">
                  <Text className="!text-base-l text-vault_text/50">
                    {messageChannel.vault?.type === VaultType.FreeOnly
                      ? 'Chat writes are locked'
                      : 'Upgrade to All Access to write a message'}
                  </Text>
                  {messageChannel.vault?.id &&
                    messageChannel.vault?.type !== VaultType.FreeOnly && (
                      <View>
                        <SubscribeButton
                          label="Upgrade"
                          linkValue={artist.linkValue}
                          artistAvatarUrl={artist.profileImage?.artistSmallProfileImageUrl}
                          price={messageChannel.vault?.price || DEFAULT_PRICE}
                          vaultId={messageChannel.vault?.id}
                          showBottomSheet={false}
                          component="locked_chat_write_access"
                          className="bg-vault_accent text-vault_accent_text"
                        />
                      </View>
                    )}
                </View>
              )}
            </>
          )}
        </>
      }
      nonScrollingChildren={
        isGroupChat && messages.length === 0 ? (
          <EmptyMessageChannel
            sendMessage={c =>
              sendMessage({
                content: c,
                vaultContent: null,
                activeSubscriptionTier: activeSubscriptionFeatures?.tier || null,
              })
            }
          />
        ) : null
      }
    >
      {messageChannel != null && (!isGroupChat || messages.length > 0) && (
        <MessageChannelView
          vaultId={messageChannel.vault?.id || ''}
          messageChannel={messageChannel}
          loadMoreNextPage={loadMoreNextPage}
          hasNextPage={hasNextPage}
          onMessageChannelUpdate={onMessageChannelUpdate}
          messages={messages}
          isOwner={isOwner}
          pinnedMessages={pinnedMessages}
          artistLinkValue={artistHandle.toLowerCase()}
          virtuosoRef={virtuosoRef}
          activeSubscriptionFeatures={activeSubscriptionFeatures}
          isGroupChat={isGroupChat}
        />
      )}
    </NewMessageLayout>
  );
};

export function LoadingChat({
  activeSubscriptionFeatures,
  chatAvailableForFreeUsers,
  isGroupChat,
  showVaultNav,
  messageChannelId,
  vaultArtistImage,
  vaultArtistName,
  vaultId,
  artistHandle,
}: {
  activeSubscriptionFeatures: TierFeatures | null;
  chatAvailableForFreeUsers: boolean;
  isGroupChat: boolean;
  showVaultNav: boolean;
  messageChannelId: string | undefined;
  vaultArtistImage: string | undefined | null;
  vaultArtistName: string | undefined;
  vaultId: string | undefined;
  artistHandle: string | undefined;
}) {
  return (
    <NewMessageLayout
      artistName={vaultArtistName}
      secondaryFooter={<SkeletonMessageTextInput />}
      artistProfileImage={vaultArtistImage}
      activeSubscriptionFeatures={activeSubscriptionFeatures}
      messageChannelId={messageChannelId}
      chatAvailableForFreeUsers={chatAvailableForFreeUsers}
      vaultId={vaultId}
      messageChannelDetails={null}
      artistHandle={artistHandle}
      isGroupChat={isGroupChat}
      showVaultNav={showVaultNav}
    >
      <SkeletonMessageChannel />
    </NewMessageLayout>
  );
}
