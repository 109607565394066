import React, { useEffect, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import { debounce } from 'lodash-es';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useGate } from 'statsig-react';
import { MetatagsHeader } from '../components/metatags/MetatagsHeader';
import { FullPageLoading } from '../components/views/FullPageLoading';
import { FEATURE_GATES } from '../constants/flagConstants';
import { ROUTES } from '../constants/routeConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useClickThroughSmsCampaign } from '../hooks/useClickThroughSmsCampaign';
import { useRedirectUserOnboarding } from '../hooks/useRedirectUserOnboarding';
import { MainProvider } from '../providers/MainProvider';
import { AboutPage } from '../screens/AboutPage';
import { AllVaultsPage } from '../screens/AllVaultsPage';
import { EventInsightsPage } from '../screens/EventInsightsPage';
import { ExplorePage } from '../screens/ExplorePage';
import { InvitesPage } from '../screens/InvitesPage';
import { LandingPage } from '../screens/LandingPage';
import { NotFoundPage } from '../screens/NotFoundPage';
import { SeeDetailsMessageChannelPage } from '../screens/SeeDetailsMessageChannelPage';
import { SpotifyConnectPage } from '../screens/SpotifyConnect';
import { TestPage } from '../screens/TestPage';
import { TextMe } from '../screens/TextPage';
import { OnboardingUsernamePage } from '../screens/auth/OnboardingUsernamePage';
import { SignInPage } from '../screens/auth/SignInPage';
import { VerifyPage } from '../screens/auth/VerifyPage';
import { SubscribePage } from '../screens/payment/SubscribePage';
import { SubscribeSuccessPage } from '../screens/payment/SubscribeSuccessPage';
import { AboutSettingsPage } from '../screens/settings/AboutSettingsPage';
import { AccountManagementPage } from '../screens/settings/AccountManagement';
import { AddPaymentMethodPage } from '../screens/settings/AddPaymentMethodPage';
import { ArtistNotificationSettingsPage } from '../screens/settings/ArtistNotificationSettingsPage';
import { BillingPage } from '../screens/settings/BillingPage';
import { EarningsBreakdownPage } from '../screens/settings/EarningsBreakdownPage';
import { EarningsPage } from '../screens/settings/EarningsPage';
import { EditArtistProfilePage } from '../screens/settings/EditArtistPage';
import { EditSubscriberProfilePage } from '../screens/settings/EditSubscriberProfilePage';
import { ManageArtistNotificationScreen } from '../screens/settings/ManageArtistNotificationScreen';
import { ManagePaymentMethodsPage } from '../screens/settings/ManagePaymentMethodsPage';
import { ManageSubscriptionPage } from '../screens/settings/ManageSubscriptionPage';
import { MySubscribersPage } from '../screens/settings/MySubscribersPage';
import { NotificationSettingsPage } from '../screens/settings/NotificationSettings';
import { PayoutsPage } from '../screens/settings/PayoutsPage';
import { SettingsPage } from '../screens/settings/SettingsPage';
import { SmsNotificationSettingsPage } from '../screens/settings/SmsNotificationSettings';
import { SmsSettingsPage } from '../screens/settings/SmsSettingsPage';
import { SubscriptionsPage } from '../screens/settings/SubscriptionsPage';
import { TestUserSettingsPage } from '../screens/settings/TestUserSettingsPage';
import { UpdatePaymentMethodPage } from '../screens/settings/UpdatePaymentMethodPage';
import { EditSnippetPage } from '../screens/vault/EditSnippetPage';
import { EditTrackPage } from '../screens/vault/EditTrackPage';
import { FinalizeTrackUploadPage } from '../screens/vault/FinalizeTrackUploadPage';
import { FolderPage } from '../screens/vault/FolderPage';
import { MediaLandingPage } from '../screens/vault/MediaLandingPage';
import { MediaUploadPage } from '../screens/vault/MediaUpload';
import { MessagesPage } from '../screens/vault/MessagesPage';
import { MoveToPage } from '../screens/vault/MoveToPage';
import { TrackLandingPage } from '../screens/vault/TrackLandingPage';
import { VaultMessageChannelPage } from '../screens/vault/VaultMessageChannelPage';
import { VaultPage } from '../screens/vault/VaultPage';
import { AnnouncementsPage } from '../screens/vault/announcement/AnnouncementsPage';
import { CreateAnnouncementPage } from '../screens/vault/announcement/Create';
import { ReviewAnnouncement } from '../screens/vault/announcement/Review';
import { CampaignPage } from '../screens/vault/campaign/CampaignPage';
import { ClaimReceiptPage } from '../screens/vault/campaign/ClaimReceiptPage';
import { CreateCampaignPage } from '../screens/vault/campaign/CreateCampaignPage';
import { EditCampaignPage } from '../screens/vault/campaign/EditCampaignPage';
import { ClaimPage } from '../screens/vault/drops/ClaimPage';
import { DropPage } from '../screens/vault/drops/DropPage';
import { CreateRsvpPage } from '../screens/vault/drops/rsvp/CreateRsvpPage';
import { EditRsvpPage } from '../screens/vault/drops/rsvp/EditRsvpPage';
import { ArtistDashboard } from '../screens/vault/membership/ArtistDashboard';
import { EventsPage } from '../screens/vault/membership/EventsPage';
import { MembershipPage } from '../screens/vault/membership/MembershipPage';
import { ReceiptsPage } from '../screens/vault/membership/ReceiptsPage';
import { CreateMassMessagePage } from '../screens/vault/message/CreateMassMessagePage';
import { EditMassMessagePage } from '../screens/vault/message/EditMassMessagePage';
import { FilterMembersScreen } from '../screens/vault/message/FilterMembersScreen';
import { MassMessageInsightsPage } from '../screens/vault/message/MassMessageInsightsPage';
import { MassMessagePreviewPage } from '../screens/vault/message/MassMessagePreviewPage';
import { MassMessagesPage } from '../screens/vault/message/MassMessagesPage';
import { SortVaultContent } from '../screens/vault/sortVault/SortVaultContent';
import { Sentry } from '../sentry';
import { LoginStatus } from '../types/authTypes';
import { sendPageAnalytics } from '../utils/analyticsUtils';
import { artistNavigationPath } from '../utils/navigationUtils';
import { getSubdomain, isValidSubdomain } from '../utils/subdomainUtils';

type Props = { children?: ReactNode };

export const MainRouter: FC<Props> = ({ children }) => {
  return (
    <BrowserRouter>
      <MainProvider>
        {children}

        <RoutesInternal />
      </MainProvider>
    </BrowserRouter>
  );
};

const useSubdomainNavigationHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const savedPathName = useRef(location.pathname);

  useEffect(() => {
    const handleSubdomainNavigation = () => {
      const currentPath = location.pathname;

      if (isValidSubdomain()) {
        const subdomain = getSubdomain();

        const routesToRemoveSubdomain = [
          ROUTES.VAULTS,
          ROUTES.EXPLORE,
          ROUTES.ABOUT,
          ROUTES.SETTINGS,
        ];

        const shouldRemoveSubdomain = routesToRemoveSubdomain.some(route =>
          currentPath.startsWith(route),
        );

        if (shouldRemoveSubdomain) {
          const newPath = currentPath.replace(subdomain + '.', '');
          const newUrl = `${window.location.protocol}//${window.location.host.replace(`${subdomain}.`, '')}${newPath}`;

          window.location.href = newUrl;
        }
      }
      savedPathName.current = currentPath;
    };

    handleSubdomainNavigation();
  }, [location.pathname, navigate]);
};

const useNavigationEvent = () => {
  const location = useLocation();
  const savedPathName = useRef(location.pathname);
  const previousAnalyticsPage = useRef(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sendAnalytics = debounce(
      () => {
        if (savedPathName.current !== location.pathname || previousAnalyticsPage.current === null) {
          const query: { [key: string]: string } = {};
          for (const entry of searchParams.entries()) {
            const key = entry[0];
            query[key] = entry[1];
          }

          sendPageAnalytics({
            subdomain: isValidSubdomain() ? getSubdomain() : null,
            pathname: location.pathname,
            previousAnalyticsPage: previousAnalyticsPage,
            searchParams: query,
          });

          savedPathName.current = location.pathname;
        }
      },
      10,
      { trailing: true },
    );

    sendAnalytics();

    return sendAnalytics.cancel;
  }, [location.pathname, searchParams]);
};

const useSmsCampaignResponseEvent = () => {
  const { clickThroughSmsCampaign } = useClickThroughSmsCampaign();
  const [searchParams] = useSearchParams();
  const smsCampaignResponseShortcode = searchParams.get('c');

  useEffect(() => {
    if (smsCampaignResponseShortcode) {
      clickThroughSmsCampaign({ shortcode: smsCampaignResponseShortcode }).catch(error => {
        Sentry.captureException(error, {
          extra: {
            message: 'Error in clickThroughSmsCampaign mutation',
            shortcode: smsCampaignResponseShortcode,
          },
          tags: {
            type: 'clickThroughSmsCampaignMutation',
          },
        });
      });
    }
  }, [clickThroughSmsCampaign, smsCampaignResponseShortcode]);
};

const generateVaultRoutes = (loginStatus: LoginStatus, isUnifiedInboxEnabled: boolean) => (
  <>
    <Route index element={<VaultPage />} />

    <Route
      path="upload"
      element={
        loginStatus === LoginStatus.LOADING ? (
          <FullPageLoading withVaultTheme />
        ) : (
          <MediaUploadPage />
        )
      }
    />
    <Route
      path="finalize-upload"
      element={
        loginStatus === LoginStatus.LOADING ? (
          <FullPageLoading withVaultTheme />
        ) : (
          <FinalizeTrackUploadPage />
        )
      }
    />
    <Route path="announcements">
      <Route index element={<AnnouncementsPage />} />
      <Route path="create" element={getElement(loginStatus, <CreateAnnouncementPage />)} />
      <Route path="review" element={getElement(loginStatus, <ReviewAnnouncement />)} />
    </Route>
    <Route path="event">
      <Route path="insights/:eventId" element={<EventInsightsPage />} />
    </Route>
    <Route path="campaign">
      <Route path="create" element={<CreateCampaignPage />} />
      <Route path="edit/:campaignId" element={<EditCampaignPage />} />
    </Route>
    <Route path="rsvp">
      <Route path="create" element={<CreateRsvpPage />} />
      <Route path="edit/:eventId" element={<EditRsvpPage />} />
    </Route>
    <Route
      path="edit/:vaultContentId"
      element={
        loginStatus === LoginStatus.LOADING ? <FullPageLoading withVaultTheme /> : <EditTrackPage />
      }
    />
    <Route
      path="snippet/:vaultContentId"
      element={
        loginStatus === LoginStatus.LOADING ? (
          <FullPageLoading withVaultTheme />
        ) : (
          <EditSnippetPage />
        )
      }
    />
    <Route path="messages">
      {isUnifiedInboxEnabled && (
        <>
          <Route index element={<MessagesPage showOnMobile="list" />} />
          <Route path="details" element={<SeeDetailsMessageChannelPage />} />
          <Route path=":channelId" element={<MessagesPage showOnMobile="detail" />} />
          <Route path=":channelId/details" element={<SeeDetailsMessageChannelPage />} />
        </>
      )}
      <Route path="insights">
        <Route index element={<MassMessagesPage />} />
        <Route path=":messageId" element={<MassMessageInsightsPage />} />
      </Route>
      <Route path="edit">
        <Route path=":messageId" element={<EditMassMessagePage />} />
        <Route path="preview" element={<MassMessagePreviewPage />} />
        <Route path="filter" element={<FilterMembersScreen />} />
      </Route>
      <Route path="create">
        <Route index element={<CreateMassMessagePage />} />
        <Route path="preview" element={<MassMessagePreviewPage />} />
        <Route path="filter" element={<FilterMembersScreen />} />
      </Route>
    </Route>
    <Route path="chat">
      <Route
        index
        element={
          isUnifiedInboxEnabled ? (
            <MessagesPage showOnMobile="detail" />
          ) : (
            <VaultMessageChannelPage />
          )
        }
      />
      {/* TODO: [Unified Inbox] Remove this route, moved to /messages/details */}
      <Route path="details" element={<SeeDetailsMessageChannelPage />} />
    </Route>
    <Route path=":vaultContentId" element={<TrackLandingPage />} />
    <Route path="t/:vaultContentSlug" element={<TrackLandingPage />} />
    <Route path="subscribe" element={<SubscribePage />} />
    <Route path="success" element={<SubscribeSuccessPage />} />
    <Route path="invites" element={<InvitesPage />} />
    <Route path="folder/:folderId" element={<FolderPage />} />
    <Route path="f/:folderSlug" element={<FolderPage />} />
    <Route path="i/:vaultContentSlug" element={<MediaLandingPage />} />
    <Route path="v/:vaultContentSlug" element={<MediaLandingPage />} />
    <Route path="image/:vaultContentId" element={<MediaLandingPage />} />
    <Route path="video/:vaultContentId" element={<MediaLandingPage />} />
    <Route path="s/:campaignSlugOrId" element={<CampaignPage />} />
    <Route path="s/:campaignSlugOrId/claim" element={<ClaimReceiptPage />} />
    <Route path="d/:dropSlug" element={<DropPage />} />
    <Route path="d/:dropSlug/claim" element={<ClaimPage />} />
    <Route path="move-to/:folderId?" element={<MoveToPage />} />
    <Route path="rearrange/:folderId?" element={<SortVaultContent />} />
    <Route path="vault" element={<VaultPage />} />
    <Route
      path="dashboard"
      element={
        loginStatus === LoginStatus.LOADING ? (
          <FullPageLoading withVaultTheme />
        ) : (
          <ArtistDashboard />
        )
      }
    />
    <Route path="drops">
      <Route
        index
        element={
          loginStatus === LoginStatus.LOADING ? (
            <FullPageLoading withVaultTheme />
          ) : (
            <MembershipPage />
          )
        }
      />
      <Route path="events" element={<EventsPage />} />
      <Route path="receipts" element={<ReceiptsPage />} />
    </Route>
    <Route path="textme" element={<TextMe />} />
    <Route path="members" element={<MySubscribersPage withVaultTheme />} />
  </>
);

const RoutesInternal: FC = () => {
  const { loginStatus, isArtist, loggedInUser } = useAuthContext();

  const { value: isUnifiedInboxEnabled } = useGate(FEATURE_GATES.UNIFIED_INBOX);

  useNavigationEvent();
  useRedirectUserOnboarding();
  useSubdomainNavigationHandler();
  useSmsCampaignResponseEvent();

  if (isValidSubdomain()) {
    return (
      <Routes>
        <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
        <Route path={ROUTES.VERIFY} element={<VerifyPage />} />
        <Route path={ROUTES.ONBOARDING}>
          <Route index element={<NotFoundPage />} />
          <Route path="username" element={<OnboardingUsernamePage />} />
        </Route>
        {generateVaultRoutes(loginStatus, isUnifiedInboxEnabled)}
        <Route path={ROUTES.EXPLORE} element={<FullPageLoading withVaultTheme={false} />} />
        <Route path={ROUTES.VAULTS} element={<FullPageLoading withVaultTheme={false} />} />
        <Route path={ROUTES.ABOUT} element={<FullPageLoading withVaultTheme={false} />} />
        <Route path={ROUTES.SETTINGS} element={<FullPageLoading withVaultTheme={false} />} />
        <Route path={ROUTES.SPOTIFY_CONNECT} element={<SpotifyConnectPage />} />
        <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
        <Route path={ROUTES.VERIFY} element={<VerifyPage />} />
        <Route path={ROUTES.SPOTIFY_CONNECT} element={<SpotifyConnectPage />} />
        <Route
          path={ROUTES.VAULTS}
          element={
            loginStatus === LoginStatus.LOADING ? (
              <FullPageLoading withVaultTheme={false} />
            ) : loginStatus === LoginStatus.LOGGED_IN ? (
              <AllVaultsPage />
            ) : (
              <AllVaultsPage />
            )
          }
        />
        {loginStatus !== LoginStatus.LOGGED_OUT && (
          <>
            <Route
              path="/test"
              element={
                loginStatus === LoginStatus.LOADING ? (
                  <FullPageLoading withVaultTheme={false} />
                ) : loggedInUser?.isInternal ? (
                  <TestPage />
                ) : (
                  <NotFoundPage />
                )
              }
            />

            <Route path={ROUTES.ONBOARDING}>
              <Route index element={<NotFoundPage />} />
              <Route path="username" element={<OnboardingUsernamePage />} />
            </Route>
            <Route path={ROUTES.SETTINGS}>
              <Route index element={<SettingsPage />} />
              <Route path="notification-settings" element={<NotificationSettingsPage />} />
              <Route path="sms-notification-settings" element={<SmsNotificationSettingsPage />} />
              <Route path="test-user-settings" element={<TestUserSettingsPage />} />
              <Route path="artist-notification">
                <Route index element={<ArtistNotificationSettingsPage />} />
                <Route path=":artistId" element={<ManageArtistNotificationScreen />} />
              </Route>
              <Route path="account-management" element={<AccountManagementPage />} />
              <Route path="profile" element={<EditSubscriberProfilePage />} />
              {isArtist && <Route path="profile-artist" element={<EditArtistProfilePage />} />}
              <Route path="subscriptions">
                <Route index element={<SubscriptionsPage />} />
                <Route path=":subscriptionId" element={<ManageSubscriptionPage />} />
              </Route>
              <Route path="memberships">
                <Route index element={<SubscriptionsPage />} />
                <Route path=":subscriptionId" element={<ManageSubscriptionPage />} />
              </Route>
              <Route path="methods">
                <Route index element={<ManagePaymentMethodsPage />} />
                <Route path="add" element={<AddPaymentMethodPage />} />
                <Route path="update" element={<UpdatePaymentMethodPage />} />
              </Route>
              <Route path="billing" element={<BillingPage />} />
              <Route path="earnings" element={<EarningsPage isArtistPage={false} />} />
              <Route path="about-vault">
                <Route index element={<AboutSettingsPage />} />
                <Route path="about" element={<AboutPage />} />
              </Route>

              {isArtist && (
                <>
                  <Route path="artist-earnings" element={<EarningsPage isArtistPage />} />
                  <Route
                    path="artist-earnings-breakdown"
                    element={<EarningsBreakdownPage isArtistPage />}
                  />
                  <Route path="subscribers" element={<MySubscribersPage />} />
                  <Route path="members" element={<MySubscribersPage />} />
                  <Route path="sms-settings" element={<SmsSettingsPage />} />
                </>
              )}
              <Route path="payouts" element={<PayoutsPage />} />
            </Route>
          </>
        )}
        <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />

        <Route path="/:artistHandle">
          {generateVaultRoutes(loginStatus, isUnifiedInboxEnabled)}
        </Route>

        <Route path={ROUTES.EXPLORE} element={<ExplorePage />} />
        {loginStatus !== LoginStatus.LOADING && (
          <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND} />} />
        )}
        <Route path={ROUTES.ABOUT} element={<AboutPage />} />
        <Route
          path={ROUTES.LANDING_PAGE}
          element={
            loginStatus === LoginStatus.LOADING ? (
              <FullPageLoading withVaultTheme={false} />
            ) : loginStatus !== LoginStatus.LOGGED_IN ? (
              <LandingPage />
            ) : loggedInUser?.artist?.mainLinkValue != null ? (
              <Navigate to={artistNavigationPath(loggedInUser.artist.mainLinkValue, '/')} replace />
            ) : (
              <Navigate to={ROUTES.VAULTS} replace />
            )
          }
        />
      </Routes>
      <MetatagsHeader />
    </>
  );
};

const getElement = (loginStatus: LoginStatus, mainElement: React.ReactNode) => {
  switch (loginStatus) {
    case LoginStatus.LOADING:
      return <FullPageLoading withVaultTheme={false} />;
    case LoginStatus.LOGGED_OUT:
      return <NotFoundPage />;
    case LoginStatus.LOGGED_IN:
      return mainElement;
  }
};
