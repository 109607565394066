import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import { useGate } from 'statsig-react';
import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';

import { isUUID4 } from '@soundxyz/utils';
import { setHideAudioPlayer } from '../audio/AudioMeta';
import { BackButton } from '../components/buttons/BackButton';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { ErrorView } from '../components/error/ErrorView';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { ListDetailLayout } from '../components/layouts/ListDetailLayout';
import { UserChannels } from '../components/messages/UserChannels';
import { SeeDetailsMessageChannelView } from '../components/views/SeeDetailsMessageChannelView';
import { FEATURE_GATES } from '../constants/flagConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useQuery } from '../graphql/client';
import {
  getFragment,
  GetMessageChannelDetailsDocument,
  MessageChannelType,
  SeeDetailsHeaderFragmentDoc,
} from '../graphql/generated';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useVaultTheme } from '../hooks/useVaultTheme';
import { useWindow } from '../hooks/useWindow';
import { LoginStatus } from '../types/authTypes';
import { artistNavigationPath } from '../utils/navigationUtils';
import { PersistenceStorage } from '../utils/storeUtils';

gql(/* GraphQL */ `
  query GetMessageChannelDetails($input: QueryMessageChannelInput!) {
    messageChannel(input: $input) {
      __typename

      ... on QueryMessageChannelSuccess {
        data {
          ...SeeDetailsHeader
        }
      }
    }
  }
`);

export const TabSchema = z.enum(['artist', 'pinned', 'attachments']);
export type SeeDetailsTab = z.infer<typeof TabSchema>;
export const DetailsTabStore = PersistenceStorage({
  schema: z.record(z.string(), TabSchema),
  key: 'detailsTab',
  eager: true,
});

export const SeeDetailsMessageChannelPage = () => {
  const { loginStatus } = useAuthContext();
  const [isAtTop, setIsAtTop] = useState(true);
  const { isDesktop } = useWindow();

  const { value: isUnifiedInboxEnabled } = useGate(FEATURE_GATES.UNIFIED_INBOX);

  const { channelId } = useParams();
  const { artistHandle } = useArtistHandle();

  useVaultTheme();

  const queryEnabled =
    (artistHandle != null || channelId != null) && loginStatus === LoginStatus.LOGGED_IN;

  const { data, isLoading, isError, refetch } = useQuery(GetMessageChannelDetailsDocument, {
    variables: {
      input:
        !!channelId && isUUID4(channelId)
          ? { messageChannelId: channelId }
          : artistHandle
            ? { artistHandle: { handle: artistHandle, type: MessageChannelType.Vault } }
            : {},
    },
    enabled: queryEnabled,
    staleTime: 0,
    select: data => {
      if (data.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess') return null;
      return data.data.messageChannel.data;
    },
  });

  useEffect(() => {
    if (!isDesktop) {
      setHideAudioPlayer(true);
    }

    return () => {
      if (!isDesktop) {
        setHideAudioPlayer(false);
      }
    };
  }, [isDesktop]);

  const messageChannel = getFragment(SeeDetailsHeaderFragmentDoc, data);

  const isOwner =
    messageChannel?.artist?.isAuthUserAdmin || messageChannel?.vault?.isUserArtistAdmin;
  const artistId = messageChannel?.artist?.id || messageChannel?.vault?.artistProfile?.id;
  const artistName = messageChannel?.artist?.name || messageChannel?.vault?.artistProfile?.name;
  const vaultId = messageChannel?.artist?.mainVaultId || messageChannel?.vault?.id;
  const isGroupChat = messageChannel?.channelType === MessageChannelType.Vault;

  const detailsTabStore = DetailsTabStore.useStore().value;

  const tab: SeeDetailsTab = isGroupChat
    ? detailsTabStore?.[artistHandle || '_'] || 'artist'
    : 'attachments';

  const errorView = (
    <ErrorView
      onRetryClick={refetch}
      loggingType="SeeDetailsMessageChannelPage"
      className="flex-1"
      withVaultTheme
    />
  );

  const mainView = (
    <SeeDetailsMessageChannelView
      isHeaderLoading={isLoading || loginStatus === LoginStatus.LOADING}
      messageChannel={data ?? null}
      tab={tab}
      artistHandle={artistHandle}
      isAtTop={isAtTop}
      setIsAtTop={setIsAtTop}
    />
  );

  // TODO: [Unified Inbox] Remove this once Unified Inbox is fully launched
  if ((isError || artistHandle == null) && !isUnifiedInboxEnabled) {
    return (
      <DefaultLayout
        withVaultTheme
        showRoundedTop
        showBorder
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={<BackButton className="text-vault_text" />}
        isHeaderTransparent={false}
        contentClassName="md2:bg-vault_text/3"
        stretch
      >
        {errorView}
      </DefaultLayout>
    );
  }

  if (loginStatus !== LoginStatus.LOADING && data == null && !isLoading) {
    return <Navigate to={artistNavigationPath(artistHandle, '/chat')} />;
  }

  // TODO: [Unified Inbox] Remove this once Unified Inbox is fully launched
  if (!isUnifiedInboxEnabled) {
    return (
      <DefaultLayout
        withVaultTheme
        showRoundedTop
        showBorder
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={<BackButton className="mt-2 text-vault_text" />}
        stretch
        childrenWrapperClassName="!px-0"
        contentClassName="md2:bg-vault_text/3"
        headerCenter={
          !isDesktop &&
          !isAtTop && (
            <Text className="mt-2 font-title !text-title-l text-vault_text">{artistName}</Text>
          )
        }
      >
        {mainView}
      </DefaultLayout>
    );
  }

  return (
    <ListDetailLayout>
      <UserChannels
        vaultId={vaultId}
        artistHandle={artistHandle}
        asArtistId={isOwner ? artistId : undefined}
        showOnMobile={false}
      />

      <ListDetailLayout.Detail showOnMobile>
        {isError && artistHandle != null ? (
          errorView
        ) : (
          <View className="flex min-h-full w-full flex-1 flex-col justify-start">
            <BackButton className="self-start pl-6 pt-9" withVaultTheme />
            {mainView}
          </View>
        )}
      </ListDetailLayout.Detail>
    </ListDetailLayout>
  );
};
