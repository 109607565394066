import { forwardRef, type ReactNode, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { faUpload } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import {
  ACCEPTED_AUDIO_MIME_TYPES,
  ACCEPTED_IMAGE_TYPES,
  ACCEPTED_VIDEO_TYPES,
  MAX_AUDIO_UPLOAD_SIZE_BYTES,
  MAX_AUDIO_UPLOAD_SIZE_TEXT,
} from '../../constants/fileConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useToast } from '../../contexts/ToastContext';
import { TrackUpload } from '../../contexts/TrackUploadContext';
import {
  ArtistLayoutFragmentDoc,
  FeatureTypename,
  type FragmentType,
  getFragment,
  TierTypename,
  VaultType,
} from '../../graphql/generated';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useUploadVaultContentFile } from '../../hooks/useUploadVaultContentFile';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { BlurContainer, getTopNavButtonClassName } from '../../screens/vault/TopNavButton';
import type { EventObject, EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { RenewSubscriptionBanner } from '../banner/RenewSubscriptionBanner';
import { MenuButton } from '../buttons/MenuButton';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { useFullScreenAudioPlayerState } from '../main/AudioPlayer';
import { SelectingVaultNav } from '../vault/SelectingVaultNav';
import { VaultNav } from '../vault/VaultNav';
import { ArtistLogo } from '../vault/items/artist/ArtistLogo';
import { DefaultLayout } from './DefaultLayout';

gql(/* GraphQL */ `
  fragment artistLayout on Artist {
    id
    name
    profileImage {
      id
      artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
    }
    membershipCardImage {
      id
      membershipCardImageUrl: imageOptimizedUrl
    }
    mainVault {
      id
      price
      messageChannelId
      activeSubscription {
        id
        status
        currentPeriodEnd
        stripeSubscriptionId
        ...ActiveSubscriptionFeatures
      }
      artist: artistProfile {
        id
        name
        linkValue
      }
      type
      contentCount
      isUserArtistAdmin
      tiers {
        __typename
        enabledFeatures {
          feature {
            __typename
          }
        }
      }
    }
    linkValue
  }
`);

const ArtistLayout = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    artist: FragmentType<ArtistLayoutFragmentDoc>;
    showProfileImage: boolean;
    rightHeader: ReactNode;
  }
>(({ children, artist, showProfileImage, rightHeader }, ref) => {
  const { isBottomAudioPlayerOpen } = useFullScreenAudioPlayerState();
  const { isDesktop } = useWindow();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const { profileImage, linkValue, mainVault, name } = getFragment(ArtistLayoutFragmentDoc, artist);
  const isOwner = mainVault.isUserArtistAdmin;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: mainVault?.activeSubscription,
    isOwner,
  });

  const isVaultFreeOnly = mainVault.type === VaultType.FreeOnly;
  const isCurrentUserFreeTier = activeSubscriptionFeatures?.tier === TierTypename.FreeTier;
  const showSubscribeButton = !isVaultFreeOnly && isCurrentUserFreeTier;

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  const chatAvailableForFreeUsers = mainVault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  const { isSelecting } = useSelectVaultContent();

  const showVaultNav = !isDesktop;

  const bottomNav = useMemo(
    () => (
      <>
        {isSelecting && isOwner ? (
          <SelectingVaultNav vaultId={mainVault.id} />
        ) : (
          showVaultNav && (
            <VaultNav
              vaultId={mainVault.id}
              messageChannelId={mainVault.messageChannelId}
              chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
              hasChatReadAccess={hasChatReadAccess}
              variant="default"
              withVaultTheme
              folderId={null}
            />
          )
        )}
      </>
    ),
    [
      chatAvailableForFreeUsers,
      hasChatReadAccess,
      isOwner,
      isSelecting,
      mainVault.id,
      mainVault.messageChannelId,
      showVaultNav,
    ],
  );

  return (
    <DefaultLayout
      showBorder
      showRoundedTop
      ref={ref}
      headerLeft={
        !isDesktop && !isSelecting ? (
          showProfileImage ? (
            <MenuButton className="h-12" withVaultTheme />
          ) : (
            <BlurContainer>
              <MenuButton className={getTopNavButtonClassName(showProfileImage)} withVaultTheme />
            </BlurContainer>
          )
        ) : null
      }
      headerCenter={
        showProfileImage &&
        (!!vaultTheme.logoMediaUrl ? (
          <ArtistLogo src={vaultTheme.logoMediaUrl} alt={name} className="md2:hidden" />
        ) : (
          <ArtistProfileImage
            profileImageUrl={profileImage?.artistSmallProfileImageUrl}
            className="h-12 w-12 md2:hidden"
          />
        ))
      }
      headerCenterClassName={showProfileImage ? 'px-6 md2:px-0' : undefined}
      headerRight={rightHeader ?? <View className="w-[50px]" />}
      banner={<RenewSubscriptionBanner artist={artist} />}
      isHeaderTransparent={!showProfileImage}
      shouldSkipMargin
      withBottomNavigator={false}
      customBottomNavigator={<View className="w-full md2:hidden">{bottomNav}</View>}
      messageChannelId={mainVault.messageChannelId}
      hasChatReadAccess={hasChatReadAccess}
      chatAvailableForFreeUsers={chatAvailableForFreeUsers}
      headerGridClassName="md2:mb-4"
      vaultId={mainVault.id}
      withVaultTheme
      headerClassName={twMerge(
        'pt-3 md2:pt-6',
        showProfileImage && !isDesktop
          ? 'border-0 border-b border-solid border-b-vault_text/5 bg-vault_background'
          : 'bg-transparent',
      )}
      footer={
        <>
          {showSubscribeButton && (
            <View
              className={twMerge(
                'absolute z-above2 w-full md2:w-[600px]',
                isBottomAudioPlayerOpen ? 'bottom-36 md2:bottom-20' : 'bottom-20 md2:bottom-0',
              )}
            >
              <View className="mx-4 flex flex-row justify-center py-4">
                <View>
                  <SubscribeButton
                    label="Upgrade to All-Access"
                    className="w-full bg-vault_accent !text-base-l text-vault_accent_text"
                    linkValue={linkValue}
                    artistAvatarUrl={profileImage?.artistSmallProfileImageUrl}
                    price={mainVault.price || DEFAULT_PRICE}
                    vaultId={mainVault.id}
                    showBottomSheet={false}
                    component="vault_page"
                  />
                </View>
              </View>
            </View>
          )}
          <View className="hidden w-full md2:block">{bottomNav}</View>
        </>
      }
    >
      {children}
    </DefaultLayout>
  );
});

const UploadButton = <Event extends EventType>({
  artistLinkValue,
  className,
  event,
  vaultId,
  customLabel,
  onNavigate,
  folderId,
  children,
}: {
  artistLinkValue: string;
  className?: string;
  event?: EventObject<Event>;
  vaultId: string;
  customLabel?: string;
  onNavigate?: () => void;
  folderId: string | null;
  children?: ReactNode;
}) => {
  const { pathname } = useLocation();
  const { saveVaultContentFile } = useUploadVaultContentFile({
    artistLinkValue,
    vaultId,
    folderId,
  });

  const { openToast } = useToast();

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    noDragEventsBubbling: true,
    async onDropAccepted([file]) {
      TrackUpload.isPromptOpen = true;
      onNavigate?.();
      await saveVaultContentFile(file);
    },
    onDropRejected() {
      openToast({
        text: `File could not be uploaded. Make sure it is a WAV or MPEG file and less than ${MAX_AUDIO_UPLOAD_SIZE_TEXT}.`,
        variant: 'error',
      });
      TrackUpload.isPromptOpen = false;
    },
    onFileDialogCancel() {
      TrackUpload.isPromptOpen = false;
    },
    accept: {
      ...ACCEPTED_AUDIO_MIME_TYPES,
      ...ACCEPTED_IMAGE_TYPES,
      ...ACCEPTED_VIDEO_TYPES,
    },
    maxFiles: 1,
    maxSize: MAX_AUDIO_UPLOAD_SIZE_BYTES,
    disabled: false,
  });

  const rootProps = getRootProps();

  return (
    <div
      className="hover:cursor-pointer"
      {...rootProps}
      onClick={(...args) => {
        event != null && trackEvent({ ...event, pathname });
        TrackUpload.isPromptOpen = true;

        rootProps.onClick?.(...args);
      }}
    >
      {children ?? (
        <View className={twMerge('flex flex-row items-center', className)}>
          <FontAwesomeIcon className="aspect-square" icon={faUpload} fontSize={24} />
          <View className="flex flex-col">
            <Text className="text-title-s font-medium text-vault_text">
              {customLabel ?? 'Upload'}
            </Text>
            <Text className="text-base-m font-normal text-vault_text/40">
              Add a song, video, or image to your vault
            </Text>
          </View>
        </View>
      )}
      <input {...getInputProps()} />
    </div>
  );
};

export { ArtistLayout, UploadButton };
